import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEnvBp } from '@bcf-v2-api-access/config-tokens/env-bp';
import { provideEnvCms } from '@bcf-v2-api-access/config-tokens/env-cms';
import { provideCpProductType, provideEnvCp } from '@bcf-v2-api-access/config-tokens/env-cp';
import { provideEnvBase } from '@bcf-v2-configs/env-base';
import { provideGcpErrorReportingBrowser } from '@bcf-v2-configurators/gcp-error-reporting/browser';
import { provideBcfProductionMode } from '@bcf-v2-configurators/tokens/app/provider';
import { providePlatformBrowserApis } from '@bcf-v2-platforms/platform-apis/provide-platform-browser-apis';
import { providePlatformWorkerEnhanced } from '@bcf-v2-platforms/platform-worker-enhanced/browser';
import { provideWorker } from '@bcf-v2-platforms/platform-worker/browser';
import { provideAppRuntimeId } from '@bcf-v2-platforms/platform-worker/common';
import { provideWorkerSynchronizerSpawnerBrowser } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { provideNoQueryParamRouterScroller } from '@bcf-v2-utilities/app-utils/no-query-param-router-scroller';
import { provideAppVisibilityBrowserProvider } from '@bcf-v2-utilities/app-visibility/app-visibility-browser-provider';
import { provideCookiesBrowserProvider } from '@bcf-v2-utilities/cookies-provider/cookies-browser-provider';
import { provideCurrentLangBrowserProvider } from '@bcf-v2-utilities/current-lang/current-lang-browser-provider';
import { provideDeviceInfoBrowserProvider } from '@bcf-v2-utilities/device-info/device-info-browser-prodiver';
import { DeviceBaseType } from '@bcf-v2-utilities/device-info/types';
import { provideIconsNotifierBrowser } from '@bcf-v2-utilities/icons-notifier/provide-icons-notifier-browser';
import { provideSvgIcons } from '@bcfds-design-system/svg-icon/svg-icons-provider';
import { environment } from '@env/env';
import { envBase, envBp, envCms, envCp } from './env-app';

export function envAppProvidersBrowser(
  worker: Worker,
  appRuntimeId: number,
  deviceKindBase?: DeviceBaseType
): (Provider | EnvironmentProviders)[] {
  return [
    provideBcfProductionMode(environment.production),
    provideEnvBase(envBase), // this should be remove after switch to worker
    provideEnvCp(envCp), // this should be remove after switch to worker
    provideEnvBp(envBp), // this should be remove after switch to worker
    provideEnvCms(envCms), // this should be remove after switch to worker
    provideNoQueryParamRouterScroller(),
    provideWorkerSynchronizerSpawnerBrowser(),
    provideAppRuntimeId(appRuntimeId),
    provideWorker(worker, appRuntimeId),
    providePlatformBrowserApis(),
    provideCookiesBrowserProvider(),
    provideCurrentLangBrowserProvider(),
    provideAppVisibilityBrowserProvider(),
    providePlatformWorkerEnhanced(),
    provideCpProductType('sportsbook'),
    provideDeviceInfoBrowserProvider(deviceKindBase ?? 'mobile-web'),
    provideGcpErrorReportingBrowser(),
    provideIconsNotifierBrowser(),
    provideSvgIcons('letsbetmd')
  ];
}
